<template>
  <v-slide-y-reverse-transition>
    <v-snackbar
      v-model="toast.toggle"
      :timeout="toast.timeout"
      :color="toast.color"
    >
      {{ toast.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          v-if="toast.button.toggle"
          text
          v-bind="attrs"
          @click="trigger(toast.button.action)"
        >
          {{ toast.button.text }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-slide-y-reverse-transition>
</template>

<style scoped>
  svg {
    display: block;
    width: 100%;
  }
</style>

<script>
  import {
    sync,
  } from 'vuex-pathify'

  export default {
    computed: {
      toast: sync('app/toast'),
    },
    methods: {
      toggleView (b) {
        this.toast = Object.assign({}, this.toast, {
          toggle: false,
          text: '',
          button: {
            toggle: false,
            texto: 'Ok',
            action: function () {
              this.toggleView(false);
            }
          },
          color: ''
        });
      },
      trigger (action) {
        if (typeof action == 'boolean') {
          this.toggleView(false);
        }else{
          action();
        }
      }
    }
  }
</script>